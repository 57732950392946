.Toastify__toast.Toastify__toast--error {
  border-radius: 10px;
}

.MuiBottomNavigationAction-root {
  font-size: 14px !important;
  opacity: "40%";
}

* {
  margin:0;
  padding:0;
}


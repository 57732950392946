
.last10card:hover {
  transform: scale(0.95);
}

.textwon {
  font-weight: 800;
  font-size: 12px;
  color: rgb(252, 179, 24);
}
.textLost {
  font-weight: 800;
  color: red;
}
.textSecond {
  font-weight: 800;
  color: rgb(9, 106, 179);
}
.textThird {
  font-weight: 800;
  color: rgb(139, 52, 191);
}
